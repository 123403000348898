import React, { Suspense, useState, useEffect } from 'react';
import { Canvas, extend, useFrame } from '@react-three/fiber';
import { OrbitControls, Center, Environment } from '@react-three/drei';
import './App.css';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import * as THREE from 'three';
import { EffectComposer, Bloom } from '@react-three/postprocessing';

// Register TextGeometry as a React component
extend({ TextGeometry })

function Stars() {
  const starCount = 2000;
  const positions = React.useMemo(() => {
    const positions = new Float32Array(starCount * 3);
    for (let i = 0; i < starCount; i++) {
      positions[i * 3] = (Math.random() - 0.5) * 2000;
      positions[i * 3 + 1] = (Math.random() - 0.5) * 2000;
      positions[i * 3 + 2] = (Math.random() - 0.5) * 2000;
    }
    return positions;
  }, []);

  // Create a circle texture programmatically
  const texture = React.useMemo(() => {
    const canvas = document.createElement('canvas');
    canvas.width = 32;
    canvas.height = 32;
    const context = canvas.getContext('2d');
    
    // Create a radial gradient
    const gradient = context.createRadialGradient(
      16, 16, 0,    // Inner circle center (x, y) and radius
      16, 16, 16    // Outer circle center (x, y) and radius
    );
    
    // Add color stops
    gradient.addColorStop(0, 'rgba(255, 255, 255, 1)');
    gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');
    
    // Draw the gradient
    context.fillStyle = gradient;
    context.fillRect(0, 0, 32, 32);
    
    const texture = new THREE.CanvasTexture(canvas);
    texture.needsUpdate = true;
    return texture;
  }, []);

  return (
    <points>
      <bufferGeometry>
        <bufferAttribute
          attach="attributes-position"
          count={positions.length / 3}
          array={positions}
          itemSize={3}
        />
      </bufferGeometry>
      <pointsMaterial
        size={4}
        color="#864a92"
        sizeAttenuation
        transparent
        opacity={1}
        map={texture}
        alphaMap={texture}
        alphaTest={0.01}
        depthWrite={false}
      />
    </points>
  );
}

// First, let's create a completely separate shooting star texture
const createShootingStarTexture = () => {
  const canvas = document.createElement('canvas');
  canvas.width = 64;
  canvas.height = 16;
  const context = canvas.getContext('2d');
  
  // Create a horizontal gradient for the streak effect
  const gradient = context.createLinearGradient(0, 8, 64, 8);
  gradient.addColorStop(0, 'rgba(255, 255, 255, 1)');
  gradient.addColorStop(0.1, 'rgba(134, 74, 146, 0.8)');
  gradient.addColorStop(0.5, 'rgba(134, 74, 146, 0.3)');
  gradient.addColorStop(1, 'rgba(134, 74, 146, 0)');
  
  context.fillStyle = gradient;
  context.fillRect(0, 0, 64, 16);
  
  return new THREE.CanvasTexture(canvas);
};

// Completely separate ShootingStars component
function ShootingStars() {
  const [shootingStars, setShootingStars] = useState([]);
  const shootingStarTexture = React.useMemo(() => createShootingStarTexture(), []);

  // Create new stars
  useEffect(() => {
    const createStar = () => {
      const angle = Math.random() * Math.PI * 2;
      const radius = 300; // Distance from center where stars spawn
      
      // Start from edge of screen
      const startX = Math.cos(angle) * radius;
      const startY = Math.sin(angle) * radius;
      const startZ = (Math.random() - 0.5) * 200;

      // Calculate direction towards center with offset
      const targetX = (Math.random() - 0.5) * 100; // Slight random offset from center
      const targetY = (Math.random() - 0.5) * 100;
      const targetZ = (Math.random() - 0.5) * 100;

      // Calculate direction vector
      const dirX = (targetX - startX);
      const dirY = (targetY - startY);
      const dirZ = (targetZ - startZ);

      // Normalize direction vector
      const length = Math.sqrt(dirX * dirX + dirY * dirY + dirZ * dirZ);
      
      return {
        id: Math.random(),
        position: [startX, startY, startZ],
        direction: [dirX/length, dirY/length, dirZ/length],
        speed: 100 + Math.random() * 100,
        createdAt: Date.now(),
        lifetime: 4000 + Math.random() * 2000
      };
    };

    const interval = setInterval(() => {
      const numNewStars = 1 + Math.floor(Math.random() * 2); // 1-2 stars at a time
      setShootingStars(prev => [
        ...prev,
        ...Array(numNewStars).fill().map(() => createStar())
      ]);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  // Update star positions
  useFrame(({ clock }) => {
    setShootingStars(prev => 
      prev.filter(star => {
        const age = Date.now() - star.createdAt;
        return age < star.lifetime;
      }).map(star => {
        const age = Date.now() - star.createdAt;
        const progress = age / star.lifetime;
        
        return {
          ...star,
          position: [
            star.position[0] + star.direction[0] * star.speed * 0.1,
            star.position[1] + star.direction[1] * star.speed * 0.1,
            star.position[2] + star.direction[2] * star.speed * 0.1
          ]
        };
      })
    );
  });

  return (
    <group>
      {shootingStars.map(star => {
        const age = Date.now() - star.createdAt;
        const progress = age / star.lifetime;
        const opacity = Math.min(1, Math.min(progress * 3, (1 - progress) * 3));

        return (
          <mesh
            key={star.id}
            position={star.position}
            rotation={[0, 0, Math.atan2(star.direction[1], star.direction[0])]}
          >
            <planeGeometry args={[20, 2]} />
            <meshBasicMaterial
              map={shootingStarTexture}
              transparent
              opacity={opacity}
              depthWrite={false}
              blending={THREE.AdditiveBlending}
              color="#864a92"
            />
          </mesh>
        );
      })}
    </group>
  );
}

function Title() {
  const [font, setFont] = useState(null);

  useEffect(() => {
    const loader = new FontLoader();
    loader.load('/fonts/UnifrakturMaguntia.json', (loadedFont) => {
      setFont(loadedFont);
    });
  }, []);

  if (!font) return null;

  const textOptions = {
    font,
    size: 10,
    height: 2,
    curveSegments: 12,
    bevelEnabled: true,
    bevelThickness: 0.4,
    bevelSize: 0.25,
    bevelOffset: 0,
    bevelSegments: 8
  };

  return (
    <Center>
      {/* Main text only */}
      <mesh position={[0, 0, 0]}>
        <textGeometry 
          args={[
            'qrime capital', 
            textOptions
          ]} 
        />
        <meshPhysicalMaterial
          color="#9387BB"
          metalness={0.9}
          roughness={0.15}
          clearcoat={1}
          clearcoatRoughness={0.1}
          reflectivity={1}
          envMapIntensity={2}
          depthWrite={true}
        />
      </mesh>
    </Center>
  );
}

function App() {
  const handleLinkClick = (link) => {
    window.open(link, '_blank');
  };

  return (
    <div className="App">
      <Canvas
        camera={{ position: [0, 0, 100], fov: 75 }}
        style={{ background: '#000000' }}
      >
        <Suspense fallback={null}>
          <Environment preset="city" />
          
          <ambientLight intensity={0.2} />
          <pointLight position={[10, 10, 10]} intensity={0.5} />
          <Stars />
          <ShootingStars />
          <Title />
          <OrbitControls
            enableZoom={false}
            enablePan={false}
            autoRotate
            autoRotateSpeed={0.5}
          />
          <Effects />
        </Suspense>
      </Canvas>

      <div className="links-container">
        <img
          src="/gif1.gif"
          alt="Link 1"
          className="clickable-image"
          onClick={() => handleLinkClick('https://x.com/TheLabProtocol')}
        />
        <img
          src="/gif2.gif"
          alt="Link 2"
          className="clickable-image"
          onClick={() => handleLinkClick('https://x.com/pawgDAO')}
        />
      </div>
    </div>
  );
}

function Effects() {
  return (
    <EffectComposer>
      <Bloom
        intensity={1.5}
        luminanceThreshold={0.6}
        luminanceSmoothing={0.9}
      />
    </EffectComposer>
  );
}

export default App;